.app {
    background-color: var(--primary-color);
    font-family: var(--font-base);
}

.app__whitebg {
    background-color: var(--white-color);
}

.app__primarybg {
    background-color: var(--primary-color);
}

.app__container {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: row;
}

.app__flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.head-text {
    font-size: 2.5rem;
    font-weight: 800;
    text-align: center;
    color: var(--black-color);
    text-transform: capitalize;

    span {
        color: var(--secondary-color);
    }

    @media screen and (min-width: 2000px) {
        font-size: 4rem;
    }

    @media screen and (max-width: 450px) {
        font-size: 2rem;
    }
}

.p-text {
    font-size: 0.8rem;
    text-align: left;
    color: var(--gray-color);
    line-height: 1.5;

    @media screen and (min-width: 2000px) {
        font-size: 1.75rem;
    }
}

.bold-text {
    font-size: 1rem;
    font-weight: 800;
    color: var(--black-color);
    text-align: left;

    @media screen and (min-width: 2000px) {
        font-size: 2rem;
    }

    @media screen and (max-width: 450px) {
        font-size: 0.9rem;
    }
}


@media screen and (max-width: 768px) {
    .app__head-text {
        margin: 2rem 0;
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .app__padding {
        padding: 2rem 1rem;
    }
}